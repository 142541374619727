import React from 'react'
import "./SingleProduct.css"
import {useParams} from "react-router-dom"
import {products} from "../../utils/ProductsData"
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined"
import {useDispatch} from "react-redux"
import {addToBasket} from "../../redux/actions"

const SingleProduct = () => {

    let {id} = useParams();
    let singleProduct = products.find((item) => item.id === id);
    let dispatch = useDispatch();

    const addItemToBasket = () => {
        const item = {
            id: singleProduct.id,
            //rating: singleProduct.rating,
            title: singleProduct.title,
            price: singleProduct.price,
            image: singleProduct.image,
            //specification: singleProduct.specification,
            //detail: singleProduct.detail,
        };

        dispatch(addToBasket(item));
    };

    return (
        <div className="single-product-container">
            <img
            className="single-product-ad"
            src="https://images-na.ssl-images-amazon.com/images/G/02/UK_CCMP/TM/OCC_Amazon1._CB423492668_.jpg"
            alt=""
            />
            <div>
                <div className="single-product">
                    <img 
                    src={singleProduct.image} 
                    className="single-product-image" 
                    alt="" 
                    />
                    <div className="single-product-info">
                        <div className="single-product-title">
                            {singleProduct.title}
                        </div>
                        <p className="single-product-price">
                            Price: <strong>$</strong>
                            <strong>{singleProduct.price}</strong>
                        </p>
                        <button onClick={addItemToBasket}>
                            <i>
                                <ShoppingCartOutlinedIcon />
                            </i>
                            Add To Basket
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SingleProduct