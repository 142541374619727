const products = [
  {
    id: "1",
    title: "PlayStation 5",
    price: 1000,
    /*rating: 5,
    specification: [
      "Test",
    ],
    detail:
      "‎Test",*/
    image:
      "/images/ItemI.jpg"
      //"https://hdclipartall.com/images/picnic-clipart--1943x1404.png",
  },
  {
    id: "2",
    title: "Kobe Bryant T-Shirt",
    price: 100,
    /*rating: 5,
    specification: [
      "Test",
    ],
    detail:
      "‎Test",*/
    image:
      "/images/ItemK.jpg"
      //"https://hdclipartall.com/images/picnic-clipart--1943x1404.png",
  },
  {
    id: "3",
    title: "Michael Jordan T-Shirt",
    price: 100,
    /*rating: 5,
    specification: [
      "Test",
    ],
    detail:
      "‎Test",*/
    image:
      "/images/ItemJ.jpg"
      //"https://hdclipartall.com/images/picnic-clipart--1943x1404.png",
  },
    {
      id: "4",
      title: "Men's Nike LeBron 16 Martin",
      price: 1000,
      //rating: 5,
      /*specification: [
        "Test",
      ],*/
      /*detail:
        "Test",*/
      image:
      "/images/ItemA.jpg"
        //"https://hdclipartall.com/images/picnic-clipart--1943x1404.png",
    },
    {
      id: "5",
      title: "Men's Nike LeBron Soldier 11",
      price: 1000,
      /*rating: 5,
      specification: [
        "Test",
      ],
      detail:
        "‎Test",*/
      image:
        "/images/ItemB.jpg"
        //"https://hdclipartall.com/images/picnic-clipart--1943x1404.png",
    },
    {
      id: "6",
      title: "Nike Men's Air Max 2017",
      price: 600,
      /*rating: 5,
      specification: [
        "Test",
      ],
      detail:
        "‎Test",*/
      image:
        "/images/ItemC.jpg"
        //"https://hdclipartall.com/images/picnic-clipart--1943x1404.png",
    },
    {
      id: "7",
      title: "Nike Air Max 720 Cool Grey",
      price: 800,
      /*rating: 5,
      specification: [
        "Test",
      ],
      detail:
        "‎Test",*/
      image:
        "/images/ItemD.jpg"
        //"https://hdclipartall.com/images/picnic-clipart--1943x1404.png",
    },
    {
      id: "8",
      title: "Air Jordan 1 High OG Black White",
      price: 1200,
      /*rating: 5,
      specification: [
        "Test",
      ],
      detail:
        "‎Test",*/
      image:
        "/images/ItemE.jpg"
        //"https://hdclipartall.com/images/picnic-clipart--1943x1404.png",
    },
    {
      id: "9",
      title: "Air Jordan Legacy 312 Wolf Grey",
      price: 1000,
      /*rating: 5,
      specification: [
        "Test",
      ],
      detail:
        "‎Test",*/
      image:
        "/images/ItemF.jpg"
        //"https://hdclipartall.com/images/picnic-clipart--1943x1404.png",
    },
    {
      id: "10",
      title: "Air Jordan Legacy 312 Black White",
      price: 1000,
      /*rating: 5,
      specification: [
        "Test",
      ],
      detail:
        "‎Test",*/
      image:
        "/images/ItemG.jpg"
        //"https://hdclipartall.com/images/picnic-clipart--1943x1404.png",
    },
    {
      id: "11",
      title: "Nike SB Stefan Janoski Max Mid Bronze",
      price: 500,
      /*rating: 5,
      specification: [
        "Test",
      ],
      detail:
        "‎Test",*/
      image:
        "/images/ItemH.jpg"
        //"https://hdclipartall.com/images/picnic-clipart--1943x1404.png",
    },
    {
      id: "12",
      title: "Nike Men's Flex Experience Run 11",
      price: 150,
      /*rating: 5,
      specification: [
        "Test",
      ],
      detail:
        "‎Test",*/
      image:
        "/images/ItemL.jpg"
        //"https://hdclipartall.com/images/picnic-clipart--1943x1404.png",
    },
    {
      id: "13",
      title: "Nike Zoom Fly 4 Prm Orange",
      price: 600,
      /*rating: 5,
      specification: [
        "Test",
      ],
      detail:
        "‎Test",*/
      image:
        "/images/ItemM.jpg"
        //"https://hdclipartall.com/images/picnic-clipart--1943x1404.png",
    },
    {
      id: "14",
      title: "Nike Men's Air Max Genome",
      price: 600,
      /*rating: 5,
      specification: [
        "Test",
      ],
      detail:
        "‎Test",*/
      image:
        "/images/ItemN.jpg"
        //"https://hdclipartall.com/images/picnic-clipart--1943x1404.png",
    },
  ];
  
  /*const headerItems = [
    "Home",
    "About",
    "Booking",
    "Contact",
    "FAQ",
    "Gallery",
    "Packages",
    "Support",
  ];*/
  
  export { products };
  //export { products, headerItems };