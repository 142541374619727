import React, {useState, useEffect} from 'react'
import "./Login.css"
import {Link, useNavigate} from "react-router-dom"
//import auction from "../../auction.JPG"
//import AmazonLogo from "../../Amazon_Logo.png"
import {useSelector, useDispatch} from "react-redux"
import { loginInitiate } from "../../redux/actions"

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const {user} = useSelector((state) => state.data);

    let dispatch = useDispatch();
    let navigate = useNavigate();
    useEffect (() => {
        if (user) {
            navigate("/")
        }
    }, [user, dispatch]);

    const signIn = (e) => {
        e.preventDefault();
        dispatch(loginInitiate(email, password));
        setEmail("");
        setPassword("");
    };
    return (
        <div className="login" style={{backgroundColor: "white", height: "100vh", display: "flex", flexDirection: "column", alignItems:"center"}}>
            {/*<Link to="/">
                <img src={auction} className="login-logo" style={{marginTop: "20px", marginBottom: "20px", objectFit:"contain", width:"100px", marginRight: "auto", marginLeft: "auto"}} />
            </Link>*/}
            <div clasName="login-container" style={{color: "#000", width: "300px", height: "fit-content", display: "flex", flexDirection: "column", borderRadius:"5px", border: "1px solid lightgray", padding: "20px"}}>
                <h1 style={{fontWeight: 500, marginBottom: "20px"}}>Sign In</h1>
                <form>
                    <h5 style={{marginBottom: "5px"}}>E-Mail</h5>
                    <input style={{color: "#000", height: "30px", marginBottom: "10px", width:"98%", backgroundColor:"white"}}
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                     <h5>Password</h5>
                    <input style={{color: "#000", height: "30px", marginBottom: "10px", width:"98%", backgroundColor:"white"}}
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <button type="submit" onClick={signIn} className="login-signIn" style={{backgroundColor: "#000", borderRadius:"2px", width:"100%", height:"30px", border:"1px solid", marginTop:"10px", color:"white", borderBottom: "1px solid lightgray"}}>
                        Sign In
                    </button>
                </form>
                <p style={{marginTop: "10px", fontSize:"14px"}}>
                    By continuing, you agree to dakcaz Conditions of Use and Privacy Notice
                </p>
                <p style={{marginTop: "10px", fontSize:"14px"}}>New to dakcaz?</p>
                <Link to="/register">
                    <button className="login-register" style={{backgroundColor: "#000", borderRadius:"2px", width:"100%", height:"30px", border:"1px solid", marginTop:"10px", color:"white", borderBottom: "1px solid lightgray"}}>Create Your dakcaz Account</button>
                </Link>
            </div>
        </div>
    );
}

export default Login

/*
<Link to="/">
    <img src={AmazonLogo} className="login-logo" alt="logo" />
</Link>
*/