import React, {useState} from 'react'
import "./Newsletter.css"
import {Link} from "react-router-dom"
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const firestore = firebase.firestore();

function Newsletter() {
  return (
    <div className="body" style={{backgroundColor: "white", display: "flex", flexDirection: "column", alignItems: "center"}}>
        <Link to="/">
            <img className="newsletter__logo" src="" alt=""/>
        </Link>

        <div className="booking__container">
            <h1></h1>
            <NewsletterRequest />
            </div>
    </div>
  );
}

function NewsletterRequest () {
  const newsletterRef = firestore.collection('newsletter');
        
  const [email, setEmail] = useState('');

  const sendNewsletter = async (e) => {
    e.preventDefault();

    await newsletterRef.add({
      email: email,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    })

    setEmail('');
}

return(<>
    <div>
     <form onSubmit={sendNewsletter} method="post" style={{textAlign: "center"}}>
         <input type="text" name="email" placeholder="Enter Email For Newsletter" value={email} onChange={(e) => setEmail(e.target.value)} style={{color: "#000000", height: "30px", marginBottom: "10px", backgroundColor: "white", width: "98%", textAlign: "center", marginTop: 5}}/>
        <button className="newsletter__submitButton" type="submit" style={{color: "white"}}>Submit</button>
        </form>
        </div>
        </>)
}

export default Newsletter
