import {Carousel} from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";

function Banner(){
    return (
        <div className="relative">
            <div className="absolute w-full">
            <Carousel
                autoPlay
                infiniteLoop
                showStatus={false}
                showIndicators={false}
                showThumbs={false}
                interval={5000}
            >
                <div>
                    <img loading="lazy" src="images/ItemI.jpg" alt="" />
                </div>
                <div>
                    <img loading="lazy" src="images/ItemK.jpg" alt="" />
                </div>
                <div>
                    <img loading="lazy" src="images/ItemJ.jpg" alt="" />
                </div>
                <div>
                    <img loading="lazy" src="images/ItemA.jpg" alt="" />
                </div>
                <div>
                    <img loading="lazy" src="images/ItemB.jpg" alt="" />
                </div>
                <div>
                    <img loading="lazy" src="images/ItemC.jpg" alt="" />
                </div>
                <div>
                    <img loading="lazy" src="images/ItemD.jpg" alt="" />
                </div>
                <div>
                    <img loading="lazy" src="images/ItemE.jpg" alt="" />
                </div>
                <div>
                    <img loading="lazy" src="images/ItemF.jpg" alt="" />
                </div>
                <div>
                    <img loading="lazy" src="images/ItemG.jpg" alt="" />
                </div>
                <div>
                    <img loading="lazy" src="images/ItemH.jpg" alt="" />
                </div>
                <div>
                    <img loading="lazy" src="images/ItemL.jpg" alt="" />
                </div>
                <div>
                    <img loading="lazy" src="images/ItemM.jpg" alt="" />
                </div>
                <div>
                    <img loading="lazy" src="images/ItemN.jpg" alt="" />
                </div>
            </Carousel>
            </div>
        </div>
    );
}

export default Banner