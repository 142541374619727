import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"

const firebaseConfig = {
    apiKey: "AIzaSyB-gWXVLSRU2tJAwoK7p8m9Zvf7uW3agaw",
    authDomain: "dakcaz-inc.firebaseapp.com",
    databaseURL: "",
    projectId: "dakcaz-inc",
    storageBucket: "dakcaz-inc.appspot.com",
    messagingSenderId: "1015004111859",
    appId: "1:1015004111859:web:0dea23bb4346ef40532886",
    measurementId: "G-G3V3NN65L7",
  };

const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();
const auth = firebase.auth();

export { db, auth };